<template>
    <div style="background: transparent">
        <v-card flat>
            <v-card-text>
                <v-row justify="end" class="my-2">
                    <v-col cols="12" sm="12" md="4" lg="6" class="text-left">
                        <el-button v-if="params.filter && Object.keys(params.filter).length > 0" type="plain"
                            @click="clearFilter" color="error">
                            <v-icon small>close</v-icon> Clear Filters
                        </el-button>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="3">
                        <el-input suffix-icon="el-icon-search" v-model="params.search" clearable placeholder="Search"
                            @keyup.enter.native="getUsers()">
                            <template slot="prepend">
                                <el-button @click="createUser()"><i class="el-icon-plus"></i></el-button>
                            </template>

                        </el-input>
                    </v-col>
                </v-row>
                <v-data-table :footer-props="{ 'items-per-page-options': [15, 30, 50, 100] }" :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc" dense :loading="loading" :items="users.data" :headers="headers">
                    <template v-slot:[`item.action`]="{ item }">
                        <v-row justify="center">
                            <v-btn small icon @click="editUser(item)"><v-icon small>edit</v-icon></v-btn>
                            <v-btn small icon @click="removeUser(item)" color="red"><v-icon small>delete</v-icon></v-btn>
                        </v-row>
                    </template>
                    <template v-slot:[`item.isActive`]="{ item }">
                        <v-chip @click="changeActiveStatus(item)" :color="item.isActive ? 'success' : 'red'" outlined
                            small>{{ item.isActive ? 'Active' : 'Inactive' }}</v-chip>
                        <!-- <el-tag size="small" style="cursor: pointer"></el-tag> -->
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog v-model="userDialog" width="600px" :fullscreen="$vuetify.breakpoint.mobile">
            <v-card>
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        Manage User
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon color="secondary" @click="saveUser" :loading="savingUser"><v-icon>save</v-icon></v-btn>
                    <v-btn text @click="userDialog = false, item = {}">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <v-text-field label="Name" v-model="item.firstname" outlined dense clearable></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <v-text-field label="Surname" v-model="item.surname" outlined dense clearable></v-text-field>

                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <v-text-field label="Email Address" v-model="item.emailAddress" outlined dense
                                clearable></v-text-field>

                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <v-select label="Authentication Provider" outlined dense v-model="item.authProvider"
                                item-text="name" item-value="value" :items="[
                                    { name: 'Google', value: 'Google' },
                                    { name: 'System', value: 'system' },
                                ]"></v-select>
                        </v-col>
                        <v-col cols="12" class="mt-0 pt-0">
                            <!-- <v-divider></v-divider> -->
                            <v-subheader style="font-size: 16px">User Type</v-subheader>
                            <v-divider></v-divider>
                            <v-row justify="center" class="my-3" :key="tagKey">
                                <v-chip class="mx-1" @click="item.tagId = userType.id, tagKey++" outlined
                                    v-for="userType in tags" :key="userType.id"
                                    :color="item.tagId == userType.id ? userType.colour : ''">{{ userType.name }}</v-chip>
                            </v-row>
                        </v-col>
                        <v-col cols="12" v-if="item.id">
                            <v-subheader style="font-size: 16px">Actions</v-subheader>
                            <v-divider></v-divider>
                            <v-row justify="center" class="my-3" :key="actionKey">
                                <v-btn outlined class="mx-1" color="blue" :loading="actionLoading == action.action" v-for="(action, index) in actions" :key="index" @click="createNotification(action.action)">
                                    <!-- <span></span>  -->
                                    {{ action.name }}
                                </v-btn>
                            </v-row>

                        </v-col>
                    </v-row>



                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- <el-dialog :visible.sync="userDialog" :fullscreen="$vuetify.breakpoint.mobile" width="750px" :title="item.modalType + ' User'" :closed="clear">
        <el-form class="modal-form" :model="item" ref="form" @submit.native.prevent="saveUser">
            <el-form-item prop="firstname">
                <el-input v-model="item.firstname" placeholder="Name" prefix-icon="fas fa-user" clearable>
                    <v-icon v-if="item.firstname" slot="suffix" class="mr-1" small color="green">check</v-icon>
                    <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                </el-input>
            </el-form-item>
            <el-form-item prop="surname">
                <el-input v-model="item.surname" placeholder="Surname" prefix-icon="fas fa-user" clearable>
                    <v-icon v-if="item.surname" slot="suffix" class="mr-1" small color="green">check</v-icon>
                    <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                </el-input>
            </el-form-item>
            <el-form-item prop="emailAddress">
                <el-input v-model="item.emailAddress" placeholder="Email Address" prefix-icon="fas fa-envelope" clearable>
                    <v-icon v-if="item.emailAddress" slot="suffix" class="mr-1" small color="green">check</v-icon>
                    <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                </el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input v-model="item.password" placeholder="Password" :type="passwordType" prefix-icon="fas fa-lock" clearable>
                    <template slot="append">
                        <v-icon v-if="passwordType!='text'" small @click="passwordType='text'">visibility</v-icon>
                        <v-icon @click="passwordType='password'" v-else small>visibility_off</v-icon>
                    </template>
                </el-input>
            </el-form-item>

            <el-form-item style="text-align: center">
                <h4>User Type</h4>
                <el-button-group :key="tagKey">
                    <el-button @click="item.tagId = userType.id, tagKey ++" plain round v-for="userType in tags" :key="userType.id" :style="{background: item.tagId == userType.id? userType.colour: 'white', color: item.tagId == userType.id? 'white': 'black', 'border-color': item.tagId == userType.id? userType.colour: 'grey'}">{{userType.name}}</el-button>
                </el-button-group>
            </el-form-item>
            <el-form-item style="text-align: center">
                <el-button v-if="item.modalType=='Add'" :loading="loading" style="min-width: 40%" type="primary" :disabled="!item.firstname || !item.surname ||!item.emailAddress ||!item.tagId" native-type="submit" block> {{!loading?'Create User':''}}</el-button>
                <el-button v-else :loading="loading" style="min-width: 40%" type="primary" :disabled="!item.firstname || !item.surname ||!item.emailAddress ||!item.tagId" native-type="submit" block> {{!loading?'Update User':''}}</el-button>

            </el-form-item>
        </el-form>
    </el-dialog> -->

    </div>
</template>

<script>
export default {
    data: () => ({
        actionKey: 2000,
        actionLoading: null,
        actions: [
            {
                name: 'Send User Invite',
                action: 'UserInvite'
            },
            // {
            //     name: 'Send Password Reset Link',
            //     action: 'ForgotPassword'
            // }
        ],
        loading: false,
        headers: [{
            text: 'Action',
            align: 'center',
            sortable: false,
            value: 'action'
        },
        {
            text: 'Name',
            value: 'firstname',
            align: 'center',
        },
        {
            text: 'Surname',
            value: 'surname',
            align: 'center',
        },
        {
            text: 'Auth Provider',
            sortable: false,
            value: "authProvider",
            align: 'center',
        },
        {
            text: 'Email',
            value: "emailAddress",
            align: 'center',
        },
        {
            text: 'Status',
            value: "isActive",
            align: 'center',
        }
        ],
        page: 1,
        params: {
            limit: 15,
            offset: 0,
            search: null
        },
        passwordType: 'password',
        item: {},
        tags: [],
        tagKey: 0,
        savingUser: false,
        sortBy: 'firstname',
        sortDesc: false,
        users: {
            count: 0,
            data: []
        },
        userDialog: false
    }),
    async created() {
        this.getUsers()
        this.getTags()
    },
    watch: {
        async page(value) {
            this.params.offset = (value - 1) * this.params.limit
            await this.getUsers()
        },
        'params.limit': {
            immediate: true,
            async handler() {
                await this.getUsers()

            }
        },
        sortBy(value) {
            console.log(value)
            this.params.sortBy = value
            this.getUsers()
        },
        sortDesc(value) {
            console.log(value)
            this.params.sortDesc = value
            this.getUsers()
        },
    },
    methods: {
        async createNotification(type) {
            try {
                this.actionLoading = type
                this.actionKey ++
                let result = await this.$API.createEmailNotification(type, {
                    userId: this.item.id
                })
                this.$message.success('Successfully sent!')
                this.actionLoading = null
                this.actionKey ++
            } catch (e) {
                this.$message.error('An error occurred')
            }
        },
        async changeActiveStatus(user) {
            user.isActive = !user.isActive
            await this.$API.updateUserAdmin({
                id: user.id,
                isActive: user.isActive
            })
            this.$message({
                type: 'success',
                message: 'Successfully updated!'
            });
        },
        clearFilter() {
            this.$refs.filterTable.clearFilter();
            this.params.filter = {}
            this.getUsers()
        },
        createUser() {
            this.item = {
                modalType: 'Add',
                tagId: 3
            }
            this.userDialog = true
        },
        editUser(user) {
            this.item = user
            this.item.modalType = 'Edit'

            this.userDialog = true
        },
        filterChange(data) {
            this.params.filter = data
            this.getUsers()
        },
        async getUsers() {
            this.loading = true
            this.users = await this.$API.getUsersAdmin({
                params: this.params
            })
            this.loading = false
        },
        async getTags() {
            this.tags = await this.$API.getTagsByType('user')
        },
        removeUser(user) {
            this.$confirm('Are you sure you want to delete this user?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                await this.$API.updateUserAdmin({
                    id: user.id,
                    isActive: false,
                    isDeleted: true
                })
                this.getUsers()
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        async saveUser() {
            this.savingUser = true
            if (this.item.id) {
                await this.$API.updateUserAdmin(this.item)
                this.$message.success('Update successful!')

            } else {
                await this.$API.createUserAdmin(this.item)
                this.$message.success('Successfully create user!')
                this.getUsers()
            }
            this.savingUser = false
            this.userDialog = false
            this.item = {}
        },
        clear() {
            this.loading = false
            this.item = {}
            this.userDialog = false
        }
    }
}
</script>

<style scoped>
.modal-form {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 10px;
}</style>
